<template>
   <router-view></router-view>
</template>

<script>
export default {
    name:'users'
}
</script>

<style scoped>

</style>